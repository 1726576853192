import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col, Card, CardBody } from "reactstrap";
import { showToast } from '../../components/Toast';
import { getAgencyOrders, getSeekerList } from '../../helper/backend-methods';

function Statistics(){
    const [seekerCount,setSeekerCount] = useState(0);
    const [seekerCountLoading,setSeekerCountLoading] = useState(true);
    const [orderCounts,setOrderCounts] = useState({ total: 0 , inProcess: 0, completed: 0 });
    const [orderCountsLoading,setOrderCountsLoading] = useState(true);

    useEffect(()=>{
        getSeekerCount();
        getOrderCounts();
    },[])

    const getSeekerCount = () => {
        getSeekerList({role_id: 1}).then(({data})=>{
            if(data.status){
                setSeekerCount(data.data.record_count)
            }
            setSeekerCountLoading(false)
        }).catch((e)=>{
            showToast("error","Request Failed",e.message)
            setSeekerCountLoading(false)
        })
    }
    const getOrderCounts = () => {
        const inProgressStatuses = [2,3,4,5,8];
        const completedStatuses = [9];
        getAgencyOrders().then(({data})=>{
            if(data.status){
                const total = data.data.record_count;
                const inProcess = data.data.orders.filter((item)=>{ return inProgressStatuses.includes(item.order_status) }).length;
                const completed = data.data.orders.filter((item)=>{ return completedStatuses.includes(item.order_status) }).length;
                setOrderCounts({ total: total , inProcess: inProcess, completed: completed });
            }
            setOrderCountsLoading(false)
        }).catch((e)=>{
            showToast("error","Request Failed",e.message)
            setOrderCountsLoading(false)
        })
    }
    
    return (
        <Row className='top-cards-container'>
            <Col md={3} className='single-card-container'>
                <Card className='number-card'>
                    <CardBody>
                        <div className="d-flex justify-between align-items-center">
                            <div className="flex-1 overflow-hidden">
                                <p className="text-truncate fs-18 mb-2 text-dark">Number of Seekers</p>
                                {
                                    seekerCountLoading ? <Skeleton height={22} width={35}/> :
                                    <h4 className="mb-0">{seekerCount ? seekerCount : 0}</h4>
                                }
                            </div>
                            <div className="text-success" style={{color: '#c09a58'}}>
                                <i className={"ri-map-pin-user-line fs-22"} style={{color: '#c09a58'}}></i>
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className="text-truncate"><small><span className="text-muted">Total number of Seekers</span></small></div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md={3} className='single-card-container'>
                <Card className='number-card'>
                    <CardBody>
                        <div className="d-flex justify-between align-items-center">
                            <div className="flex-1 overflow-hidden">
                                <p className="text-truncate fs-18 mb-2 text-dark">Total Orders</p>
                                {
                                    orderCountsLoading ? <Skeleton height={22} width={35}/> :
                                    <h4 className="mb-0">{orderCounts ? orderCounts.total : 0}</h4>
                                }
                            </div>
                            <div className="text-success" style={{color: '#c09a58'}}>
                                <i className={"ri-shopping-basket-line fs-22"} style={{color: '#c09a58'}}></i>
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className="text-truncate"><small><span className="text-muted">Number of orders created</span></small></div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md={3} className='single-card-container'>
                <Card className='number-card'>
                    <CardBody>
                        <div className="d-flex justify-between align-items-center">
                            <div className="flex-1 overflow-hidden">
                                <p className="text-truncate fs-18 mb-2 text-dark">Umrah in Process</p>
                                {
                                    orderCountsLoading ? <Skeleton height={22} width={35}/> :
                                    <h4 className="mb-0">{orderCounts ? orderCounts.inProcess : 0}</h4>
                                }
                            </div>
                            <div className="text-success" style={{color: '#c09a58'}}>
                                <i className={"ri-arrow-go-back-line fs-22"} style={{color: '#c09a58'}}></i>
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className="text-truncate"><small><span className="text-muted">Number of Umrah in process</span></small></div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md={3} className='single-card-container'>
                <Card className='number-card'>
                    <CardBody>
                        <div className="d-flex justify-between align-items-center">
                            <div className="flex-1 overflow-hidden">
                                <p className="text-truncate fs-18 mb-2 text-dark">Umrah Completed</p>
                                {
                                    orderCountsLoading ? <Skeleton height={22} width={35}/> :
                                    <h4 className="mb-0">{orderCounts ? orderCounts.completed : 0}</h4>
                                }
                            </div>
                            <div className="text-success" style={{color: '#c09a58'}}>
                                <i className={"ri-checkbox-circle-line fs-22"} style={{color: '#c09a58'}}></i>
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className="text-truncate"><small><span className="text-muted">Number of Umrah completed</span></small></div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );

}
export default Statistics;