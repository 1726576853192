
// Api Routes

//ORDERS
export const GET_PACKAGES = "/packages"
export const CREATE_ORDER = "/order-post"

// FOR AGENCY ORDERS
export const GET_AGENCY_SINGLE_ORDER_DATA = "/getagencyorderbyid"

//Services
export const GET_SERVICE_DATA = "/service"

// For Seeker Agency 
export const GENERATE_INVOICE = "/codes-invoice"
export const GET_SEEKER_LIST = "/agency-users"
export const GET_AGENCY_CODES = "/codesbyagencyid"
export const GET_AGENCY_INVOICES = "/agency-codes-invoice"
export const GET_AGENCY_CODE_USAGE = "/seeker-agency-usage"
export const AGENCY_ORDERS = "/agency-orders"
export const GET_AGENCY_AGENTS = "/get-sub-agent"
export const POST_AGENCY_AGENT = "/create-sub-agent"
export const SEEKER_AGENCY_DETAILS_WEB = "/seeker-agency-details-web"