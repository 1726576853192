import React, { useEffect, useRef, useState } from "react";
import OrderManagement from "./OrderManagement";
import PaymentPage from "./PaymentPage";
import PurchaseCode from "./PurchaseCode";
import Invoices from "./Invoices";
import ReportAnalysis from "./ReportAnalysis";
import MyProfile from "./MyProfile";
import "../../assets/css/agent-agency.css";
import DashboardLogo from "../../assets/images/dashboardLogo.png"
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';

const AgencyDashboard = () => {
    const [activeTab, setActiveTab] = useState("OrderManagement");
    const [user, setUser] = useState({});
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const history = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let userObject = localStorage.getItem("user");
        try {
            userObject = JSON.parse(userObject);
            setUser(userObject);
        } catch (error) {
            return false;
        }
    }, [])
    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath === "/order-management") {
            setActiveTab("OrderManagement")
        }
        else if (currentPath === "/payments") {
            setActiveTab("PaymentPage")
        }
        else if (currentPath === "/purchase-code") {
            setActiveTab("PurchaseCode")
        }
        else if (currentPath === "/invoices") {
            setActiveTab("Invoices")
        }
        else if (currentPath === "/report-analysis") {
            setActiveTab("ReportAnalysis")
        }
        else if (currentPath === "/agency-profile") {
            setActiveTab("AgencyProfile")
        }
        else {
            setActiveTab("OrderManagement")
        }
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    function toggleDropdownn() {
        setDropDownOpen(!dropDownOpen);
    }
    const handleOptionClick = () => {
        setDropDownOpen(false);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropDownOpen(false);
        }
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case "OrderManagement":
                return <OrderManagement />;
            case "PaymentPage":
                return <PaymentPage />;
            case "PurchaseCode":
                return <PurchaseCode />;
            case "Invoices":
                return <Invoices />;
            case "ReportAnalysis":
                return <ReportAnalysis />;
            case "AgencyProfile":
                return <MyProfile />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="dashboard-header" style={{direction: "ltr"}}>
                <div className="dashboard-header-wrapper pl-2 pr-2">
                    <div className="section-left">
                        <img src={DashboardLogo} alt="" />
                    </div>
                    <div className="section-right">
                        <div className="agency-badge umrah-dropdown" ref={dropdownRef} onClick={toggleDropdownn} style={{ cursor: "pointer" }}>
                            <div className="agency-icon">
                                <i className="ri-user-line"></i>
                            </div>
                            <span className="agency-name">{user && user.name ? user.name : ""}</span>
                            {dropDownOpen && (
                                <div className="umrah-dropdown-content" style={{ top: "38px", left: "0px" }}>
                                    <button onClick={() => { handleOptionClick(); history('/logout') }}>Logout</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-wrapper" style={{direction: "ltr"}}>
                {/* Tabs Header */}
                <div className="tabs">
                    {/* <NavLink className="button" onClick={() => setActiveTab("OrderManagement")}>Order Management</NavLink>
                    <NavLink className="button" onClick={() => setActiveTab("PaymentPage")}>Payment Page</NavLink>
                    <NavLink className="button" onClick={() => setActiveTab("PurchaseCode")}>Purchase Code</NavLink>
                    <NavLink className="button" onClick={() => setActiveTab("Invoices")}>Invoices</NavLink>
                    <NavLink className="button" onClick={() => setActiveTab("ReportAnalysis")}>Report and Analysis</NavLink> */}
                    <NavLink className="button" to={'/order-management'} onClick={() => setActiveTab("OrderManagement")}>Order Management</NavLink>
                    <NavLink className="button" to={'/payments'} onClick={() => setActiveTab("PaymentPage")}>Payment Page</NavLink>
                    <NavLink className="button" to={'/purchase-code'} onClick={() => setActiveTab("PurchaseCode")}>Purchased Codes</NavLink>
                    <NavLink className="button" to={'/invoices'} onClick={() => setActiveTab("Invoices")}>Invoices</NavLink>
                    <NavLink className="button" to={'/report-analysis'} onClick={() => setActiveTab("ReportAnalysis")}>Report and Analysis</NavLink>
                    <NavLink className="button" to={'/agency-profile'} onClick={() => setActiveTab("AgencyProfile")}>My Profile</NavLink>
                    {/* <NavLink className="button" to={'/'}>Report and Analysis</NavLink> */}
                </div>

                {/* Render Active Tab Content */}
                <div className="tab-content">
                    {renderTabContent()}
                </div>
            </div>
        </>
    );
};

export default AgencyDashboard;