import { get, post, apiWrapper } from "./api-methods";
import * as url from "./api-urls"

// get orders
export const getPackagesList = (data) => apiWrapper(get,url.GET_PACKAGES,data)
export const createOrder = (data) => apiWrapper(post,url.CREATE_ORDER,data)

// get services
export const getServicesData = () => apiWrapper(get,url.GET_SERVICE_DATA)

// For Seeker Agency 
export const generateInvoice = (data) => apiWrapper(post,url.GENERATE_INVOICE,data)
export const getSeekerList = (data) => apiWrapper(post,url.GET_SEEKER_LIST,data)
export const getAgencyCodes = (params) => apiWrapper(get,url.GET_AGENCY_CODES,params)
export const getAgencyInvoices = (params) => apiWrapper(get,url.GET_AGENCY_INVOICES,params)
export const getAgencyCodeUsage = (params) => apiWrapper(get,url.GET_AGENCY_CODE_USAGE,params)
export const getAgencyOrders = (params) => apiWrapper(get,url.AGENCY_ORDERS,params)
export const getAgencyAgents = (params) => apiWrapper(get,url.GET_AGENCY_AGENTS,params)
export const postAgencyAgent = (data) => apiWrapper(post,url.POST_AGENCY_AGENT,data)
export const seekerAgencyDetailsWeb = (data) => apiWrapper(get,url.SEEKER_AGENCY_DETAILS_WEB,data)
