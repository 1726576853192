import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

function Logout(){
    const history = useNavigate();
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        localStorage.removeItem("authToken"); 
        localStorage.removeItem("expiry"); 
        localStorage.removeItem("refresh");
        localStorage.removeItem("user");
        localStorage.removeItem("reset");
        setTimeout(()=>{ 
            const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
            if(language === "ar"){
                history('/');
            }else{
                history('/en');
            }
        }, 1000)
    },[])

    return(
        <div className="page-content">
            <div id="preloader" className="d-flex" style={{"opacity": 0.8}}>
                <div id="status">
                    <div className="spinner">
                        <i className="ri-loader-line spin-icon"></i>
                    </div>
                </div>
            </div>
            <Container fluid>
                <Row>
                    <Col>
                        <p>Safely logging you out of your account. Please Wait...</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Logout;