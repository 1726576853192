import React, { useEffect, useState } from 'react';
import { Input, Alert, Label } from "reactstrap";
import { Link, useNavigate } from 'react-router-dom';
import { postLogin } from '../../helper/api-methods';
import Navbar from "../../components/NavbarInner";
import Footer from "../../components/FooterInner";
import { languages } from '../../common/languages'
import mainLogo from "../../assets/images/login-logo.png"
import "../../assets/css/agent-agency.css";

function Login({ language }) {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ username: "", password: "", apiError: "" });
    const history = useNavigate();

    useEffect(() => {
        let authToken = localStorage.getItem("authToken");
        if(authToken){
            history("/agent-dashboard");
        }
    }, [])

    const validateSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const error = { username: "", password: "", apiError: "" }
        let flag = false;
        if (!username || username.replaceAll(/\s/g, '').length < 3 || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(username)) {
            error.username = 'Please provide a valid email address';
            flag = true
        }
        if (!password || password.replaceAll(/\s/g, '').length < 6) {
            error.password = 'Please provide a valid password';
            flag = true
        }
        setErrors(error);
        if (!flag) {
            postLogin("/login", { email: username.toLowerCase(), password: password }).then(({ data }) => {
                localStorage.setItem("authToken", data.access_token)
                localStorage.setItem("expiry", data.expires_in)
                localStorage.setItem("refresh", data.refresh_token.token)
                localStorage.setItem("reset", data.user.is_reset_password_required)
                localStorage.setItem("user", JSON.stringify({ id: data.user.id + data.user.role_id, email: data.user.email, name: data.user.full_name, picture: data.user.profile_image_url, agency: data.agency && data.agency.id ? data.agency.id : null }))
                history('/agent-dashboard');
            }).catch((e) => {
                const error = { username: "", password: "", apiError: e }
                setErrors(error);
                resetForm();
                setLoading(false)
            })
        }
        else {
            setLoading(false)
        }
    }

    const resetForm = () => {
        setUsername("");
        setPassword("");
    }
    return (
        <>
            <Navbar language={languages[language]} />
            <section id='agentAgencyHero' style={{direction: "ltr"}}>
                <div className='wrapper agentAgencyHero-wrapper'>
                    <div className='section-left'>
                        <img src={mainLogo} alt="Tawkeel" />
                        <h1 className='secondary-heading' style={{color: "#000"}}>{"Log in to your agency account"}</h1>
                        {errors.apiError ? <Alert color="danger">{errors.apiError}</Alert> : null}
                        <div className="p-2 mt-5">
                            <form className="form-horizontal" onSubmit={validateSubmit}>
                                <div className="auth-form-group-custom mb-4">
                                    <i className="ri-user-fill auti-custom-input-icon"></i>
                                    <Input name="username" value={username} onChange={(e) => { setUsername(e.target.value.slice(0, 100).toLowerCase()) }} type="text" className={`form-control ${errors.username ? "is-invalid" : ""}`} id="username" placeholder="Email" />
                                    {errors.username ? <div className="invalid-feedback">{errors.username}</div> : null}
                                </div>
                                <div className="auth-form-group-custom mb-4">
                                    <i className="ri-lock-fill auti-custom-input-icon"></i>
                                    <Input name="password" value={password} onChange={(e) => { setPassword(e.target.value.slice(0, 100)) }} type="password" className={`form-control ${errors.password ? "is-invalid" : ""}`} id="userpassword" placeholder="**********" />
                                    {errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
                                </div>
                                <div className='d-flex justify-between align-items-center'>
                                    <div className="form-check">
                                        <Input type="checkbox" className="form-check-input" id="customControlInline" />
                                        <Label className="form-check-label" htmlFor="customControlInline">Remember me</Label>
                                    </div>
                                    <div className="text-center">
                                        <Link to="/forgot-password" className="text-muted login-links"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                    </div>
                                </div>
                                <div className="mt-4 text-center">
                                    <button disabled={loading} className="btn-login" type="submit">{loading ? "Please Wait" : "LOGIN"}</button>
                                </div>

                            </form>
                        </div>
                        <div className='btn-group hero-btn-group'>

                        </div>
                    </div>
                    <div className='section-right'>
                    </div>
                </div>
            </section>
            <Footer language={languages[language]} />
        </>
    );
}

export default Login